<template>
    <div class="detail">
        <el-tabs type="border-card" @tab-click="tabClick" v-model="tabValue">
            <el-tab-pane label="片区详情" name="1">
                <div class="search2-part">
                    <el-select
                        v-model="search1.addrId"
                        placeholder="请选择"
                        multiple
                        filterable
                        collapse-tags
                        clearable
                        size="small"
                        style="width: 250px"
                        @change="getAreaList(1)"
                    >
                        <el-option
                        v-for="item in taskAddrdata"
                        :key="item.trueId"
                        :label="item.name"
                        :value="item.trueId"
                        >
                        </el-option>
                    </el-select>
                    <!-- <el-cascader
                        clearable
                        v-model="search1.addrId"
                        :options="taskAddrdata"
                        filterable
                        :props="{
                            value: 'trueId',
                            label: 'name',
                            disabled: 'disable',
                            emitPath: false,
					        multiple: true
                        }"
                        collapse-tags
                        size="small"
                        style="width: 250px"
                        @change="getAreaList"
                    ></el-cascader> -->
                    <!-- <el-button type="primary" size="small" @click="getAreaList(1)"
                        style="margin-left:10px"
                    >查询</el-button
                    >
                    <el-button type="info" size="small" @click="resetArea"
                    >重置</el-button
                    > -->
                    <div style="float: right">
                        <span style="color: #909399">总户数：</span>
                         {{houseCount}}
                    </div>
                </div>
                <el-table
                    ref="tableData1"
                    :data="tableData1"
                    height="calc(100vh - 380px)"
                    style="margin-top: 10px"
                    border
                    :header-cell-style="{
                        color: '#333',
                        fontFamily: 'MicrosoftYaHeiUI',
                        fontSize: '14px',
                        fontWeight: 900,
                        background: '#f8f8f9',
                        textAlign: 'center',
                    }"
                    >
                    <el-table-column
                        type="index"
                        label="序号"
                        width="150"
                        align="center"
                    ></el-table-column>
                    <el-table-column prop="addrName" label="片区名称" align="center" width="400"> </el-table-column>
                    <el-table-column prop="taskCount" label="任务户数" align="center">
                        <template slot-scope="{row}">
                            <span style="color: #1082ff; cursor: pointer"
                                @click="watchUser(row)">{{row.taskCount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="completeCount" label="已检查数" align="center"> </el-table-column>
                    <el-table-column prop="completePercent" label="已检查率" align="center"> </el-table-column>
                    <el-table-column prop="doneCount" label="已完成数" align="center"> </el-table-column>
                    <el-table-column prop="donePercent" label="已完成率" align="center"> </el-table-column>
                </el-table>
                <div class="pageBox">
                    <el-pagination
                    :current-page="search1.current"
                    :background="true"
                    :page-sizes="[10, 30, 50,100]"
                    :page-size="search1.size"
                    layout="total, prev, pager, next, sizes, jumper"
                    :total="total1"
                    @size-change="handleSizeChange1"
                    @current-change="handleCurrentChange1"
                    >
                    </el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="用户详情" name="2">
                <div class="table">
                    <!--搜索框-->
                    <el-form :inline="true" :model="search2" size="small">
                        <el-form-item label="安检结果">
                            <el-select v-model="search2.safetyResults"
                                placeholder="请选择"
                                style="width: 180px"
                                clearable
                                multiple
                                collapse-tags
                                >
                                <!-- @change="userSearch(1)" -->
                                <el-option
                                    v-for="item in checkResList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="问题登记">
                            <el-select v-model="search2.problem"
                                placeholder="请选择"
                                style="width: 180px"
                                clearable
                                multiple
                                collapse-tags
                                >
                                <!-- @change="userSearch(1)" -->
                                <el-option
                                    v-for="item in probList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="工单状态">
                            <el-select v-model="search2.statusFlag"
                                placeholder="请选择"
                                style="width: 180px"
                                clearable
                                multiple
                                collapse-tags
                                >
                                <!-- @change="userSearch(1)" -->
                                <el-option
                                    v-for="item in orderStatusList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="审核状态">
							<el-select
								v-model="search2.auditStatus"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
                            <!-- @change="userSearch(1)" -->
								<el-option
									v-for="item in auditStatusList"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
                        <el-form-item label="片区">
                            <el-cascader
                                clearable
                                v-model="addrIds"
                                :options="taskAddrdata"
                                filterable
                                :props="{
                                    value: 'trueId',
                                    label: 'name',
                                    disabled: 'disable',
                                    checkStrictly: true
                                }"
                                size="small"
                                style="width: 200px"
                            ></el-cascader>
                            <!-- @change="userSearch(1)" -->
                        </el-form-item>
                        <el-form-item label="">
							<el-input
								placeholder="请输入关键字"
								v-model="search2.keyWordValue"
							>
								<el-select
									placeholder="请选择"
									v-model="search2.keyWord"
									slot="prepend"
								>
									<el-option
										v-for="item in searchBar"
										:key="item.id"
										:label="item.label"
										:value="item.id"
									></el-option>
								</el-select>
							</el-input>
						</el-form-item>
                        <el-form-item>
                            <div>
                                <el-button type="primary" size="small" @click="userSearch(1)"
                                >查询</el-button
                                >
                                <el-button type="warning" size="small" @click="resetArea2"
                                >重置</el-button
                                >
                                <el-button size="small" type="primary" @click="exportFile"
                                >导出</el-button
                                >
                            </div>
                        </el-form-item>
                    </el-form>
                    <!--数据表格-->
                    <el-table
                        ref="tableData2"
                        :data="tableData2"
                        style="width: 100%; margin: 15px 0"
                        border
                        height="calc(100vh - 420px)"
                        :stripe="true"
                        :header-cell-style="{
                        color: '#333',
                        fontFamily: 'MicrosoftYaHeiUI',
                        fontSize: '14px',
                        fontWeight: 900,
                        background: '#f8f8f9',
                        textAlign:'left',
                        }"
                    >
                        <el-table-column type="index" label="序号" width="60"> </el-table-column>
                        <el-table-column prop="residentName" label="户主姓名" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="mobile" label="户主电话" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="addrName" label="片区" show-overflow-tooltip>
                            <template slot-scope="{row}">
                                {{row.addrName}}->{{row.addrsName}}->{{row.addrssName}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="sheetSteel" label="表编号" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="safetyResults" label="安检结果" show-overflow-tooltip>
                            <template slot-scope="{row}">
                                <span v-if="row.safetyResults==1">正常入户</span>
                                <span v-else-if="row.safetyResults==2">到访不遇</span>
                                <span v-else-if="row.safetyResults==3">拒绝安检</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="inspectorName" label="安检员" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="lastTime" label="安检时间" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="problem" label="问题登记" show-overflow-tooltip>
                            <template slot-scope="{row}">
                                <span v-if="row.problem==1">无问题</span>
                                <span v-else-if="row.problem==2">有问题未处理</span>
                                <span v-else-if="row.problem==3">问题已处理</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="statusFlag" label="工单状态"  show-overflow-tooltip>
                            <template slot-scope="{row}">
                                <span v-if="!row.statusFlag" style="color: #F56C6C">未安检</span>
                                <span v-else-if="row.statusFlag==1" style="color: #67C23A">已完成</span>
                                <span v-else-if="row.statusFlag==2" style="color: #E6A23C">未完成</span>
                                <span v-else-if="row.statusFlag==3" style="color: #4872E4">待复查</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="auditStatus" label="审核状态"  show-overflow-tooltip>
                            <template slot-scope="{row}">
                                <span v-if="row.auditStatus==1" style="color: #67C23A">未审核</span>
                                <span v-else-if="row.auditStatus==2" style="color: #E6A23C">已审核</span>
                                <span v-else-if="row.auditStatus==3" style="color: #4872E4">驳回</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="{row}">
                                <span style="color: #1082ff; cursor: pointer"
                                    v-if="row.statusFlag"
                                    @click="watchOut(row)"
                                    >
                                        查看
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pageBox">
                        <el-pagination
                            :current-page="search2.current"
                            :background="true"
                            :page-sizes="[30, 50, 100]"
                            :page-size="search2.size"
                            layout="total, prev, pager, next, sizes, jumper"
                            :total="total2"
                            @size-change="handleSizeChange2"
                            @current-change="handleCurrentChange2"
                        >
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="导出中..." :visible.sync="deriveVisible" width="30%">
			<el-table v-loading="loading" empty-text="导出中"> </el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
			</span>
		</el-dialog>
    </div>
</template>
 
<script>
import { getAddrDropDown } from "@/RequestPort/workorder/maintenRecord";
import {
  taskDetailAddrPageList,
  taskDetailUserPageList,
  taskDetailUserListExportExcel
} from "../../../RequestPort/selectQuery/residentRequest.js";
import { exportMethod } from "../../../common/js/exportExcel";
export default {
    name: 'rightDetail',
    props: ['houseCount'],
    data () {
        return {
            tabValue: '1',
            addrs: [],
            search1: {
                addrId: '',	// 片区id
                taskId: this.$route.query.id,	// 任务id
                current: 1,	// 当前页,默认值为1
                size: 100,	// 当前页条数,默认值为10
            },
            total1: 0,
            tableData1: [],
            search2: {
                safetyResults: '',	// 安检结果 1正常入户 2到访不遇 3拒绝安检
                addrId: '',	// 片区id
                taskId: this.$route.query.id,	// 任务id
                problem: '',	// 问题登记 1无问题 2有问题未处理 3问题已处理
                statusFlag: '',	// 工单状态 1已完成 2未完成 3待复查
                auditStatus: '',
                userName: '',	// 用户名
                userPhone: '', // 电话
                address: '',	// 地址
                sheetSteel: '',	// 表编号
                size: 100,	// 当前页条数,默认值为10
                current: 1,	// 当前页,默认值为1
                keyWord: 4,
                keyWordValue: ''
            },
            dialogSearchObj2: {},
            total2: 0,
            tableData2: [],
            checkResList: [
                {label: '正常入户',id: 1},
                {label: '到访不遇',id: 2},
                {label: '拒绝安检',id: 3},
                // {label: '停业',id: 4},
            ],
            probList: [
                {label: '无问题',id: 1},
                {label: '有问题未处理',id: 2},
                {label: '问题已处理',id: 3},
            ],
            orderStatusList: [
                {label: '未安检',id: '0'},
                {label: '已完成',id: 1},
                {label: '未完成',id: 2},
                {label: '待复查',id: 3},
            ],
            searchBar: [
                {label: '户主姓名',id: 4},
                {label: '户主电话',id: 1},
                {label: '地址',id: 2},
                {label: '表编号',id: 3},
            ],
            taskAddrdata: [], // 片区下拉
            addrIds: [],
            auditStatusList: [
                { label: "未审核", id: 1 },
                { label: "已审核", id: 2 },
                { label: "驳回", id: 3 },
            ],
            deriveVisible: false,
            loading: true,
        }
    },
    mounted() {
        this.getAreaList()
        this.copyObj2(1)
        this.gettaskAddrList()
    },
    methods: {
        exportFile() {
            this.deriveVisible = true
            if (this.addrIds.length == 1) {
                this.dialogSearchObj2.addrId = this.addrIds[0]
                this.dialogSearchObj2.addrsId = ''
            } else if (this.addrIds.length == 2) {
                this.dialogSearchObj2.addrsId = this.addrIds[1]
                this.dialogSearchObj2.addrId = ''
            } else {
                this.dialogSearchObj2.addrsId = ''
                this.dialogSearchObj2.addrId = ''
            }
            let obj = {}
            
            if(this.dialogSearchObj2.keyWord == 1) {
                this.dialogSearchObj2.userName = ''
                this.dialogSearchObj2.userPhone = this.dialogSearchObj2.keyWordValue
                this.dialogSearchObj2.address = ''
                this.dialogSearchObj2.sheetSteel = ''
            } else if (this.dialogSearchObj2.keyWord == 2) {
                this.dialogSearchObj2.userName = ''
                this.dialogSearchObj2.userPhone = ''
                this.dialogSearchObj2.address = this.dialogSearchObj2.keyWordValue
                this.dialogSearchObj2.sheetSteel = ''
            } else if (this.dialogSearchObj2.keyWord == 3) {
                this.dialogSearchObj2.userName = ''
                this.dialogSearchObj2.userPhone = ''
                this.dialogSearchObj2.address = ''
                this.dialogSearchObj2.sheetSteel = this.dialogSearchObj2.keyWordValue
            } else if (this.dialogSearchObj2.keyWord == 4) {
                this.dialogSearchObj2.userName = this.dialogSearchObj2.keyWordValue
                this.dialogSearchObj2.userPhone = ''
                this.dialogSearchObj2.address = ''
                this.dialogSearchObj2.sheetSteel = ''
            }
            for(let key in this.dialogSearchObj2) {
                if (key == 'safetyResults' || key == 'problem' || key == 'statusFlag' || key == 'auditStatus') {
                    obj[key] = this.dialogSearchObj2[key] ? this.dialogSearchObj2[key].toString() : ''
                } else {
                    obj[key] = this.dialogSearchObj2[key]
                }
            }
            taskDetailUserListExportExcel(obj).then(res => {
                this.deriveVisible = false
                exportMethod(res,'查看任务用户详情')
                this.$store.commit('setCancelTokenList',null)
            })
        },
        cancel(){
            this.deriveVisible = false
            // if(!this.editConductVisible){
                // this.$store.getters.cancelTokenList('取消接口')
                window.stop()
            // }
        },
        watchUser(row) {
            this.addrIds = [row.id]
            this.tabValue = '2'
            this.handleCurrentChange2(1)
        },
        watchOut(row) {
            const routeData = this.$router.resolve({
                path: '/home/workorder/civilDetails',      //跳转目标窗口的地址
                query: {
                    residentId: row.id,
                    dataId: row.dataId,
                    taskId: this.$route.query.id,
                    safetyResults: row.safetyResults,
                    type: (row.safetyResults==1 && row.statusFlag==1) || row.finishType == 3 ? 1 : 0,
                    auditStatus: '',
                } 
            })
            window.open(routeData.href);
        },
        getUserList() {
            if (this.addrIds.length == 1) {
                this.dialogSearchObj2.addrId = this.addrIds[0]
                this.dialogSearchObj2.addrsId = ''
            } else if (this.addrIds.length == 2) {
                this.dialogSearchObj2.addrsId = this.addrIds[1]
                this.dialogSearchObj2.addrId = ''
            } else {
                this.dialogSearchObj2.addrsId = ''
                this.dialogSearchObj2.addrId = ''
            }
            let obj = {}
            
            if(this.dialogSearchObj2.keyWord == 1) {
                this.dialogSearchObj2.userName = ''
                this.dialogSearchObj2.userPhone = this.dialogSearchObj2.keyWordValue
                this.dialogSearchObj2.address = ''
                this.dialogSearchObj2.sheetSteel = ''
            } else if (this.dialogSearchObj2.keyWord == 2) {
                this.dialogSearchObj2.userName = ''
                this.dialogSearchObj2.userPhone = ''
                this.dialogSearchObj2.address = this.dialogSearchObj2.keyWordValue
                this.dialogSearchObj2.sheetSteel = ''
            } else if (this.dialogSearchObj2.keyWord == 3) {
                this.dialogSearchObj2.userName = ''
                this.dialogSearchObj2.userPhone = ''
                this.dialogSearchObj2.address = ''
                this.dialogSearchObj2.sheetSteel = this.dialogSearchObj2.keyWordValue
            } else if (this.dialogSearchObj2.keyWord == 4) {
                this.dialogSearchObj2.userName = this.dialogSearchObj2.keyWordValue
                this.dialogSearchObj2.userPhone = ''
                this.dialogSearchObj2.address = ''
                this.dialogSearchObj2.sheetSteel = ''
            }
            for(let key in this.dialogSearchObj2) {
                if (key == 'safetyResults' || key == 'problem' || key == 'statusFlag' || key == 'auditStatus') {
                    obj[key] = this.dialogSearchObj2[key] ? this.dialogSearchObj2[key].toString() : ''
                } else {
                    obj[key] = this.dialogSearchObj2[key]
                }
            }
            taskDetailUserPageList(obj).then(res => {
                this.tableData2 = res.data.records
                this.total2 = res.data.total
            })
            
        },
        copyObj2(val) {
            if (val==1) {
                // 正向拷贝
                for(let key in this.search2) {
                    this.dialogSearchObj2[key] = this.search2[key]
                }
            } else if (val==2) {
                // 反向拷贝
                for(let key in this.search2) {
                    this.search2[key] = this.dialogSearchObj2[key]
                }
            }
        },
        userSearch() {
            this.search2.current = 1
            this.copyObj2(1)
            this.getUserList()
        },
        resetArea2() {
            this.addrIds = []
            this.search2 = {
                safetyResults: '',	// 安检结果 1正常入户 2到访不遇 3拒绝安检
                addrId: '',	// 片区id
                addrsId: '',
                taskId: this.$route.query.id,	// 任务id
                address: '',	// 地址
                problem: '',	// 问题登记 1无问题 2有问题未处理 3问题已处理
                sheetSteel: '',	// 表编号
                statusFlag: '',	// 工单状态 1已完成 2未完成 3待复查
                auditStatus: '',
                userName: '',	// 用户名
                userPhone: '', // 电话
                size: 100,	// 当前页条数,默认值为10
                current: 1,	// 当前页,默认值为1
                keyWord: 4,
                keyWordValue: ''
            }
            this.copyObj2(1)
            this.getUserList()
        },
        handleSizeChange2(val) {
            this.copyObj2(2)
            this.search2.size = val
            this.search2.current = 1
            this.dialogSearchObj2.size = val
            this.dialogSearchObj2.current = 1

            this.getUserList()
        },
        handleCurrentChange2(val) {
            this.dialogSearchObj2.current = val
            this.copyObj2(2)
            this.getUserList()
        },
        tabClick(e) {
            console.log(e.index)
            if (e.index == 0) {
                this.handleCurrentChange1(1)
            } else if (e.index == 1) {
                this.handleCurrentChange2(1)
            }
        },
        /* ------------------ **/
        getAreaList(val) {
            console.log(val)
            val ? this.search1.current = val : ''
            let obj = {}
            for(let key in this.search1) {
                if (key == 'addrId') {
                    obj[key] = this.search1[key].toString()
                } else {
                    obj[key] = this.search1[key]
                }
            }
            taskDetailAddrPageList(obj).then(res => {
                this.tableData1 = res.data.records
                this.total1 = res.data.total
            })
        },
        resetArea() {
            this.search1 = {
                addrId: '',	// 片区id
                taskId: this.$route.query.id,	// 任务id
                current: 1,	// 当前页,默认值为1
                size: 100,	// 当前页条数,默认值为10
            }
            this.getAreaList()
        },
        handleSizeChange1(val) {
            this.search1.size = val
            this.search1.current = 1
            this.getAreaList()
        },
        handleCurrentChange1(val) {
            this.search1.current = val
            this.getAreaList()
        },
        /**
         * 片区下拉
         */
        gettaskAddrList() {
            getAddrDropDown().then((res) => {
                res.data.forEach(item=>{
                    if(item.children){
                        item.disable = false
                    }else {
                        item.disable = true
                    }
                    this.taskAddrdata = res.data
                })
            });
        },
    },
}
</script>
 
<style scoped lang="less">
.detail {
    padding: 20px;
}
/deep/.el-input-group__prepend {
    width: 60px;
}
</style>