import { render, staticRenderFns } from "./rightDetail.vue?vue&type=template&id=cfaac826&scoped=true"
import script from "./rightDetail.vue?vue&type=script&lang=js"
export * from "./rightDetail.vue?vue&type=script&lang=js"
import style0 from "./rightDetail.vue?vue&type=style&index=0&id=cfaac826&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfaac826",
  null
  
)

export default component.exports